import React, {FunctionComponent, useEffect, useState} from 'react';
import {Divider, Drawer, List, ListItem, ListItemIcon, ListItemText,} from '@material-ui/core';
import {ExitToApp} from '@material-ui/icons';
import {Theme, withStyles} from '@material-ui/core/styles';
import i18n from '../i18n/i18n';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {logout} from '../redux/login/actions';
import {
    DrawerGroupItem,
    DrawerPreferencesItem,
    DrawerSectionItem,
    DrawerStudentItem,
    DrawerTestItem,
    DrawerUserItem,
} from '../components';
import * as routes from './screens/routes';
import {drawer} from '../styles';
import DrawerSessionItem from '../components/DrawerSessionItem';
import {createStyles} from '@material-ui/styles';
import {AppState, Role} from '../helpers/types';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';

export const drawerWidth = 240;

const styles = (theme: Theme) => createStyles({
    drawer: {
        zIndex: drawer.zIndex,
        width: drawerWidth,
        flexShrink: 0,
        position: 'absolute',
    },
    drawerClosed: {
        width: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerPaperClosed: {
        width: 40,
    },
    toolbar: theme.mixins.toolbar,
    deployTrigger: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        height: 40,
        width: 40,
    },
});

type StateProps = {
    roles: Role[];
}

type DispatchProps = {
    logout: () => void;
}

type Props = {
    isOpen: boolean;
    classes: { [key: string]: string };
} & StateProps & DispatchProps & RouteComponentProps;

const DrawerMenu: FunctionComponent<Props> = ({isOpen, classes, roles, logout, history}: Props) => {
    const [isAdmin, setIsAdmin] = useState(roles.filter(role => role.name === 'ADMIN').length > 0);
    const [isCoach, setIsCoach] = useState(roles.filter(role => role.name === 'COACH').length > 0);
    const [isSectionManager, setIsSectionManager] = useState(roles.filter(role =>
        role.name === 'SECTION_MANAGER').length > 0);

    useEffect(() => {
        setIsAdmin(roles.filter(role => role.name === 'ADMIN').length > 0);
        setIsCoach(roles.filter(role => role.name === 'COACH').length > 0);
        setIsSectionManager(roles.filter(role =>
            role.name === 'SECTION_MANAGER').length > 0);
    }, [roles]);

    const handleLogout = () => {
        logout();
        history.push(routes.LOGIN_ROUTE);
    };

    const handleTestCreation = () => {
        history.push(routes.TEST_CREATION_ROUTE);
    };

    const handleShowAllTestsClick = () => {
        history.push(routes.TEST_SHOW_ALL_ROUTE);
    };

    const handleStudentCreateClick = () => {
        history.push(routes.STUDENT_CREATION_ROUTE);
    };

    const handleCreateGroupClick = () => {
        history.push(routes.CREATE_GROUP_ROUTE);
    };

    const handleShowAllGroupsClick = () => {
        history.push(routes.SHOW_ALL_GROUPS_ROUTE);
    };

    const handleCreateSectionClick = () => {
        history.push(routes.CREATE_SECTION_ROUTE);
    };

    const handleShowAllSectionsClick = () => {
        history.push(routes.SHOW_ALL_SECTIONS_ROUTE);
    };

    const handleCreateUserClick = () => {
        history.push(routes.CREATE_USER_ROUTE);
    };

    const handleShowAllUsersClick = () => {
        history.push(routes.SHOW_ALL_USERS_ROUTE);
    };

    const handlePreferencesClick = () => {
        history.push(routes.USER_PREFERENCES_ROUTE);
    };

    const handleSubscriptionsClick = () => {
        history.push(routes.SHOW_ALL_SUBSCRIPTIONS_ROUTE);
    };

    const handleShowAllSessionsClick = () => {
        history.push(routes.SHOW_ALL_SESSIONS_ROUTE);
    };

    return (
        <Drawer
            className={`${classes.drawer} ${!isOpen && classes.drawerClosed}`}
            variant="permanent"
            classes={{
                paper: isOpen ? classes.drawerPaper : classes.drawerClosed,
            }}
        >
            <div className={classes.toolbar}/>
            <List>
                <React.Fragment>
                    {isAdmin ?
                        <DrawerTestItem
                            onTestCreateClick={handleTestCreation}
                            onShowAllClick={handleShowAllTestsClick}
                        /> : null
                    }
                    {isAdmin ?
                        <DrawerStudentItem
                            onCreateStudentClick={handleStudentCreateClick}
                            onSubscriptionsClick={handleSubscriptionsClick}
                        /> : null
                    }
                    {isAdmin || isSectionManager || isCoach ?
                        <DrawerGroupItem
                            readOnly={!isAdmin && !isSectionManager}
                            onCreateGroupClick={handleCreateGroupClick}
                            onShowAllClick={handleShowAllGroupsClick}
                        /> : null
                    }
                    {isAdmin ?
                        <DrawerSectionItem
                            onCreateSectionClick={handleCreateSectionClick}
                            onShowAllClick={handleShowAllSectionsClick}
                        /> : null
                    }
                    {isAdmin ?
                        <DrawerUserItem
                            onCreateUserClick={handleCreateUserClick}
                            onShowAllClick={handleShowAllUsersClick}
                        /> : null
                    }
                    <DrawerSessionItem
                        onShowAllClick={handleShowAllSessionsClick}/>
                </React.Fragment>
            </List>
            <Divider/>
            <List>
                <DrawerPreferencesItem
                    onClick={handlePreferencesClick}
                />
                <ListItem button onClick={handleLogout}>
                    <ListItemIcon><ExitToApp/></ListItemIcon>
                    <ListItemText primary={i18n.t('LOGOUT')}/>
                </ListItem>
            </List>
        </Drawer>
    );
};

const mapStateToProps = (state: AppState): StateProps => {
    const {roles} = state.login;
    return {roles};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, unknown, AnyAction>): DispatchProps => ({
    logout: () => dispatch(logout())
});

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(DrawerMenu)));
