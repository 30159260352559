import React, {ChangeEvent, FunctionComponent, ReactNode, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
    Button, Checkbox,
    createStyles,
    CssBaseline, FormControl, FormControlLabel,
    IconButton, InputLabel,
    List,
    MenuItem,
    Paper,
    Select,
    TextField,
    Theme,
    Typography,
    withStyles
} from '@material-ui/core';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {appBar} from '../../styles';
import AppBar from '../AppBar';
import {AppState, Coach, CoachResponse, errorMessages, Token, Type} from '../../helpers/types';
import {GroupDetailsData, Pack} from '../../helpers/responseTypes';
import {DrawerMenu} from '../index';
import i18n from '../../i18n/i18n';
import {Connector} from '../../helpers/Connector';
import {AddCircleOutlineOutlined, CheckBoxOutlineBlank, RemoveCircleOutlineOutlined} from '@material-ui/icons';
import {SnackBarMessage} from '../../components';
import {SnackBarType} from '../../components/SnackBarMessage';
import {SelectProps} from '@material-ui/core/Select';
import ErrorView from '../../components/ErrorView';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
    },
    content: {
        marginTop: theme.spacing(3),
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paper: {
        width: '70%',
        flex: 1,
        marginTop: appBar.height,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
    },
    input: {
        width: '90%',
        flex: 0.2,
        marginTop: 5,
        marginBottom: 5,
    },
    coachList: {
        flexDirection: 'column',
        width: '90%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        textAlignVertical: 'center',
    },
    coachName: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        textAlignVertical: 'center',
    },
    addIcon: {
        flex: 0.2
    },
    addCoachDiv: {
        flex: 0.3,
        width: '90%',
        display: 'flex',
        flexDirection: 'row',
    },
    coachSelect: {
        flex: 0.8,
    },
    buttonRow: {
        display: 'flex',
        flexDirect: 'row',
        width: '90%',
    },
    button: {
        margin: theme.spacing(2)
    },

});

type StateProps = {
    token: Token;
    login: string;
}

type Props = {
    classes: { [key: string]: string };
} & StateProps & RouteComponentProps;

type LocationState = {
    id: number;
}

const GroupEdit: FunctionComponent<Props> = ({classes, history, location, token, login}: Props) => {
    const [group, setGroup] = useState<GroupDetailsData | null>(null);
    const [groupId] = useState((location.state as LocationState).id);
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [price, setPrice] = useState(0);
    const [priceError, setPriceError] = useState(false);
    const [reSubPrice, setReSubPrice] = useState(0);
    const [reSubPriceError, setReSubPriceError] = useState(false);
    const [allCoaches, setAllCoaches] = useState<CoachResponse[]>([]);
    const [selectedCoaches, setSelectedCoaches] = useState<Coach[]>([]);
    const [selectedNewCoach, setSelectedNewCoach] = useState<number>(0);
    const [requestFailed, setRequestFailed] = useState(false);
    const [failMessage, setFailMessage] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [packArray, setPackArray] = useState<Pack[]>([]);
    const [pack, setPack] = useState<Pack | null>(null);
    const [typeArray, setTypeArray] = useState<Type[]>([]);
    const [type, setType] = useState<Type | null>(null);
    const [ffnLicense, setFfnLicense] = useState<boolean>(false);
    const [emailList, setEmailList] = useState('');
    const [emailListError, setEmailListError] = useState(false);
    const [errorList, setErrorList] = useState<string[]>([]);

    useEffect(() => {
        if (!token.access) {
            history.push('/login');
        }
    }, [history, token.access]);

    useEffect(() => {
        if (group) {
            const {name, pack, price, coaches, resubPrice, type} = group.group;
            setName(name);
            setPack(pack);
            setType(type);
            setPrice(price);
            setReSubPrice(resubPrice);
            setSelectedCoaches(coaches);
        }
    }, [group]);


    useEffect(() => {
        Connector.getInstance().getGroupDetails(groupId)
            .then((groupResponse): void => {
                if ((groupResponse as GroupDetailsData).group) {
                    setGroup(groupResponse as GroupDetailsData);
                    setFfnLicense((groupResponse as GroupDetailsData).group.ffnLicense);
                    setEmailList((groupResponse as GroupDetailsData).group.emails);
                    Connector.getInstance().getAllCoaches()
                        .then((coaches: CoachResponse[] | string | undefined): void => {
                            if (Array.isArray(coaches as CoachResponse[])) {
                                setAllCoaches((coaches as CoachResponse[]));
                                setSelectedNewCoach((coaches as CoachResponse[])[0].id);
                                Connector.getInstance().getPacks()
                                    .then(packs => {
                                        if (!errorMessages.includes(packs as string)) {
                                            setPackArray(packs as Pack[]);
                                        }
                                    });
                                Connector.getInstance().getAllTypes()
                                    .then(types => {
                                        if (!errorMessages.includes(types as string)) {
                                            setTypeArray(types as Type[]);
                                        }
                                    });
                            } else {
                                setRequestFailed(true);
                                setFailMessage(coaches === undefined ? 'UNKNOWN_ERROR' : (coaches as string));
                            }
                        });
                } else {
                    setFailMessage(groupResponse as string);
                    setRequestFailed(true);
                }
            })
            .catch((e: Error): void => {
                e.message === 'LOGOUT' && history.push('/login');
            });

    }, [groupId, history]);

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => setName(e.currentTarget.value);

    const handleNameBlur = () => setNameError(!name);

    const isEmailListValid = (): boolean => {
        if (emailList === '') {
            return true;
        }
        const simpleEmailRegex = /^[^ ,@]+@[^ ,@]+\.[^@ ,]+$/;
        const emailAddresses = emailList.split(',').map(email => email.trim());
        let isValid = true;
        for (const email of emailAddresses) {
            if (!email.match(simpleEmailRegex)) {
                isValid = false;
            }
        }
        return isValid;
    };

    const handleEmailListChange = (e: ChangeEvent<HTMLInputElement>) => setEmailList(e.currentTarget.value);

    const handleEmailListBlur = () => {
        setEmailListError(!isEmailListValid());
    };

    const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) =>
        setPrice(parseInt(e.currentTarget.value || '0'));

    const handlePriceBlur = () => setPriceError(price === undefined || price === null);

    const handleReSubPriceChange = (e: ChangeEvent<HTMLInputElement>) =>
        setReSubPrice(parseInt(e.currentTarget.value || '0'));

    const handleReSubPriceBlur = () => setReSubPriceError(reSubPrice === undefined || reSubPrice === null);

    const handleNewCoachChange = (e: ChangeEvent<{ name?: string | undefined; value: unknown; }>): void => {
        setSelectedNewCoach(e.target.value as number);
    };
    
    const handlePackChange = (e: React.ChangeEvent<{name?: string; value: unknown}>) => {
        setPack(packArray.filter(pack => pack.id === e.target.value)[0]);
    };
    
    const handleTypeChange = (e: React.ChangeEvent<{name?: string; value: unknown}>) => {
        setType(typeArray.filter(type => type.id === e.target.value)[0]);
    };
    
    const renderPack = (value: SelectProps['value']): ReactNode =>
        <Typography>
            {value
                ? packArray.filter(pack => pack.id === value)[0].label
                : ''}
        </Typography>;
    const renderType = (value: SelectProps['value']): ReactNode =>
        <Typography>
            {value
                ? i18n.t(typeArray.filter(type => type.id === value)[0].label)
                : ''}
        </Typography>;

    const handleRemoveClick = (id: number): void => {
        const newSelectedCoaches = selectedCoaches.filter(coach => coach.id !== id);
        setSelectedCoaches(newSelectedCoaches);
    };

    useEffect(() => {
        const newSelectedCoach = allCoaches
            .filter(coach => !selectedCoaches
                .map(coach => coach.id)
                .includes(coach.id)
            )[0];
        if (newSelectedCoach)
            setSelectedNewCoach(newSelectedCoach.id);
    }, [selectedCoaches, allCoaches]);

    const handleAddClick = () => {
        const newCoach = allCoaches.filter(coach => coach.id === selectedNewCoach)[0];
        if ((newCoach as Coach) !== undefined) {
            const newSelectedCoaches = selectedCoaches.concat(newCoach as Coach);
            setSelectedCoaches(newSelectedCoaches);
        }
    };

    const handleCancelClick = () => {
        history.goBack();
    };

    const isNameValid = (): boolean => {
        return name !== '';
    };

    const isPriceValid = (price: number): boolean => price >= 0 && price < 500;



    const handleSubmitClick = () => {
        const errors = [];
        if (!isNameValid()) {
            errors.push('GROUP_NAME_ERROR');
            setNameError(true);
        }
        if (!isPriceValid(price)) {
            errors.push('GROUP_PRICE_ERROR');
            setPriceError(true);
        }
        if (!isPriceValid(reSubPrice)) {
            errors.push('GROUP_RESUB_PRICE_ERROR');
            setReSubPriceError(true);
        }
        if (type === null) {
            errors.push('TYPE_ERROR');
        }
        if (pack === null) {
            errors.push('PACK_ERROR');
        }
        if (!isEmailListValid()) {
            errors.push('EMAIL_LIST_ERROR');
            setEmailListError(true);
        }

        if (errors.length === 0) {
            Connector.getInstance().editGroup(groupId, {
                name,
                price,
                pack,
                type,
                reSubPrice,
                ffnLicense,
                coaches: selectedCoaches.map(coach => coach.id),
                emailList
            })
                .then((ok: boolean | string) => {
                    if (!errorMessages.includes(ok as string))
                        history.goBack();
                    else {
                        setRequestFailed(true);
                        setFailMessage(ok as string);
                    }
                });
        } else {
            setErrorList(errors);
        }
    };

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar login={login} currentRoute={location.pathname} onBurgerClick={() => {
                setIsMenuOpen(!isMenuOpen);
            }}/>
            <DrawerMenu isOpen={isMenuOpen}/>
            <main className={classes.content}>
                <Paper className={classes.paper}>
                    <Typography component="h3" variant="h6">
                        {i18n.t('GROUP_EDIT_TITLE')}
                    </Typography>
                    <TextField
                        className={classes.input}
                        variant="outlined"
                        required
                        fullWidth
                        id="name"
                        label={i18n.t('GROUP_NAME_LABEL')}
                        name="name"
                        placeholder={i18n.t('GROUP_NAME_LABEL')}
                        value={name}
                        onChange={handleNameChange}
                        onBlur={handleNameBlur}
                        error={nameError}
                    />
                    <TextField
                        className={classes.input}
                        variant="outlined"
                        required
                        fullWidth
                        id="name"
                        label={i18n.t('GROUP_PRICE_LABEL')}
                        name="name"
                        placeholder={i18n.t('GROUP_PRICE_LABEL')}
                        value={price || ''}
                        onChange={handlePriceChange}
                        onBlur={handlePriceBlur}
                        error={priceError}
                        type="number"
                    />
                    <TextField
                        className={classes.input}
                        variant="outlined"
                        required
                        fullWidth
                        id="name"
                        label={i18n.t('GROUP_RESUB_PRICE_LABEL')}
                        name="name"
                        placeholder={i18n.t('GROUP_RESUB_PRICE_LABEL')}
                        value={reSubPrice || ''}
                        onChange={handleReSubPriceChange}
                        onBlur={handleReSubPriceBlur}
                        error={reSubPriceError}
                        type="number"
                    />
                    <TextField
                        className={classes.input}
                        variant="outlined"
                        required
                        fullWidth
                        id="emailList"
                        label={i18n.t('GROUP_EMAILS_FIELD')}
                        name="emailList"
                        placeholder={i18n.t('GROUP_EMAILS_FIELD')}
                        value={emailList || ''}
                        onChange={handleEmailListChange}
                        onBlur={handleEmailListBlur}
                        error={emailListError}
                    />
                    {typeArray.length
                        ? <FormControl fullWidth className={classes.input}>
                            <InputLabel>{i18n.t('TYPE_FIELD')}</InputLabel>
                            <Select
                                fullWidth
                                value={type ? type.id : ''}
                                name="type"
                                id="type"
                                displayEmpty
                                onChange={handleTypeChange}
                                renderValue={renderType}
                            >
                                {typeArray.map(type =>
                                    <MenuItem key={type.id.toString()}
                                        value={type.id}>{i18n.t(type.label)}</MenuItem>)}
                            </Select>
                        </FormControl> : null}
                    {packArray.length
                        ? <FormControl fullWidth className={classes.input}>
                            <InputLabel>{i18n.t('PACK_FIELD')}</InputLabel>
                            <Select
                                fullWidth
                                value={pack ? pack.id : ''}
                                name="pack"
                                id="pack"
                                displayEmpty
                                onChange={handlePackChange}
                                renderValue={renderPack}
                            >
                                {packArray.map(pack =>
                                    <MenuItem key={pack.id.toString()}
                                        value={pack.id}>{pack.label}</MenuItem>)}
                            </Select>
                        </FormControl> : null}
                    <FormControlLabel
                        className={classes.input}
                        control={<Checkbox checked={ffnLicense} value={ffnLicense}
                            icon={<CheckBoxOutlineBlank color="primary"/>}
                            color="primary"
                            onChange={(): void => setFfnLicense(!ffnLicense)}/>}
                        label={i18n.t('FFN_LICENSE')}
                    />
                    <List className={classes.coachList}>
                        {selectedCoaches.map(coach =>
                            <div className={classes.coachName} key={coach.id}>
                                <Typography>
                                    {coach.firstName} {coach.lastName}
                                </Typography>
                                <IconButton onClick={() => {
                                    handleRemoveClick(coach.id);
                                }}>
                                    <RemoveCircleOutlineOutlined/>
                                </IconButton>
                            </div>
                        )}
                    </List>
                    <div className={classes.addCoachDiv}>
                        {allCoaches !== null && selectedNewCoach !== 0 ? <Select
                            className={classes.coachSelect}
                            value={selectedNewCoach}
                            onChange={handleNewCoachChange}
                        >
                            {allCoaches.filter(coach => !selectedCoaches.map(coach => coach.id).includes(coach.id))
                                .map(coach => <MenuItem key={coach.id} value={coach.id}>
                                    {coach.firstName} {coach.lastName}
                                </MenuItem>)}
                        </Select>
                            : null
                        }
                        <IconButton className={classes.addIcon} onClick={handleAddClick}>
                            <AddCircleOutlineOutlined/>
                        </IconButton>
                    </div>
                    <div className={classes.buttonRow}>
                        <Button
                            variant="contained"
                            color="default"
                            className={classes.button}
                            onClick={handleCancelClick}
                        >
                            {i18n.t('CANCEL_BUTTON')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={handleSubmitClick}
                        >
                            {i18n.t('SUBMIT_BUTTON')}
                        </Button>
                    </div>
                    <ErrorView errorList={errorList}/>
                </Paper>
            </main>
            <SnackBarMessage content={i18n.t(failMessage)} type={SnackBarType.ERROR} isOpen={requestFailed}/>

        </div>
    );
};

const mapStateToProps = (state: AppState): StateProps => {
    const {token, login} = state.login;
    return {token, login};
};

export default withStyles(styles)(withRouter(connect(mapStateToProps, null)(GroupEdit)));
