import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from '@material-ui/core';
import SectionSelect from './SectionSelect';
import CoachSelect from './CoachSelect';
import i18n from '../i18n/i18n';
import ErrorView from './ErrorView';
import {Connector} from '../helpers/Connector';
import {errorMessages} from '../helpers/types';

const styles = theme => ({
    root: {
        display: 'flex',
        width: '100%',
    },
    form: {
        width: '100%',
    },
    gridItem: {
        padding: theme.spacing(2)
    }
});

class GroupCreationForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: this.props.name || '',
            nameError: false,
            price: this.props.price || 0,
            priceError: false,
            reSubPrice: this.props.reSubPrice || 0,
            reSubPriceError: false,
            errorList: [],
            types: [],
            type: '',
            typeError: false,
            packs: [],
            pack: '',
            packError: false,
            groupEmails: '',
            groupEmailsError: false,
        };
    }
    
    componentDidMount() {
        Connector.getInstance().getPacks()
            .then(packs => {
                if (!errorMessages.includes(packs)) {
                    Connector.getInstance().getAllTypes()
                        .then(types => {
                            if (!errorMessages.includes(types)) {
                                this.setState({
                                    packs: packs,
                                    types: types,
                                });
                            }
                        });
                } else {
                    this.setState({errorList: [packs]});
                }
            }).catch(e => {
                if (e.message === 'LOGOUT') this.props.logout();
                else
                    this.setState({errorList: [e.message || e]});
            });
    }

    handleNameChange = e => this.setState({name: e.target.value});
    handleNameFocus = () => this.setState({nameError: false});

    isNameValid = () => (/^[0-9a-zA-Z\-éèàïâêö ']+$/g.test(this.state.name));
    isPriceValid = (price) => price >= 0 && price < 500;

    handleNameBlur = () => this.setState({nameError: !this.isNameValid()});

    handlePriceBlur = () => this.setState({priceError: !this.isPriceValid(this.state.price)});

    handlePriceFocus = () => this.setState({priceError: false});

    handlePriceChange = e => this.setState({price: e.target.value});

    handleReSubPriceBlur = () => this.setState({reSubPriceError: !this.isPriceValid(this.state.reSubPrice)});

    handleReSubPriceFocus = () => this.setState({reSubPriceError: false});

    handleReSubPriceChange = e => this.setState({reSubPrice: e.target.value});

    handleTypeChange = e => this.setState({
        type: this.state.types.filter(type => type.id === e.target.value)[0],
        typeError: e.target.value === '',
    });

    handleTypeFocus = () => this.setState({typeError: false});

    handlePackChange = e => this.setState({
        pack: this.state.packs.filter(pack => pack.id === e.target.value)[0],
        packError: e.target.value === '',
    });

    handlePackFocus = () => this.setState({packError: false});

    isEmailListValid = () => {
        const simpleEmailRegex = /^[^ ,@]+@[^ ,@]+\.[^@ ,]+$/;
        const emailAddresses = this.state.groupEmails.split(',').map(email => email.trim());
        let isValid = true;
        for (let email of emailAddresses) {
            if (!email.match(simpleEmailRegex)) {
                isValid = false;
            }
        }

        this.setState({groupEmailsError: !isValid});

        return isValid;
    }

    handleGroupEmailChange = e => {
        this.setState({groupEmails: e.target.value});
    }

    handleGroupEmailFocus = () => this.setState({groupEmailsError: false});

    handleGroupEmailBlur = () => this.setState({groupEmailsError: !this.isEmailListValid()});

    handleCancelClick = () => this.props.onCancelClick();

    handleSubmitClick = () => {
        const {name, price, reSubPrice, pack, type, groupEmails} = this.state;
        let errorList = [];
        if (!this.isNameValid()) {
            errorList.push('GROUP_NAME_ERROR');
            this.setState({nameError: true});
        }
        if (!this.isPriceValid(this.state.price)) {
            errorList.push('GROUP_PRICE_ERROR');
            this.setState({priceError: true});
        }
        if (!this.isPriceValid(this.state.reSubPrice)) {
            errorList.push('GROUP_RESUB_PRICE_ERROR');
            this.setState({priceError: true});
        }
        if (this.state.type === '') {
            errorList.push('TYPE_ERROR');
            this.setState({typeError: true});
        }
        if (this.state.pack === '') {
            errorList.push('PACK_ERROR');
            this.setState({packError: true});
        }
        if (!this.isEmailListValid()) {
            errorList.push('EMAIL_LIST_ERROR');
            this.setState({groupEmailsError: true});
        }

        if (errorList.length === 0) {
            const section = this.sectionSelect.getSelected();
            const coach = this.coachSelect.getSelected();

            if (section && coach && pack && type) {
                const connector = Connector.getInstance();
                connector.createGroup(name, price, reSubPrice, section.id, coach.id, pack.id, type.id, groupEmails)
                    .then(groupId => {
                        if (groupId && !errorMessages.includes(groupId))
                            this.props.onCreated(groupId);
                        else
                            this.props.onRequestFailed(groupId || 'UNKNOWN_ERROR');
                    })
                    .catch(err => {
                        if (err.message === 'LOGOUT') this.props.logout();
                        else
                            this.setState({errorList: ['UNEXPECTED_ERROR']});
                    });
            }
        } else {
            this.setState({errorList: errorList});
        }

    };

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <form className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid  item xs={12} sm={6} className={classes.gridItem}>
                            <SectionSelect ref={ref => this.sectionSelect = ref} logout={this.props.logout}/>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.gridItem}>
                            <TextField
                                required
                                fullWidth
                                id="name"
                                label={i18n.t('GROUP_NAME_LABEL')}
                                value={this.state.name}
                                onChange={this.handleNameChange}
                                error={this.state.nameError}
                                onFocus={this.handleNameFocus}
                                onBlur={this.handleNameBlur}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.gridItem}>
                            <TextField
                                required
                                fullWidth
                                id="price"
                                type="number"
                                label={i18n.t('GROUP_PRICE_LABEL')}
                                value={this.state.price}
                                onChange={this.handlePriceChange}
                                error={this.state.priceError}
                                onFocus={this.handlePriceFocus}
                                onBlur={this.handlePriceBlur}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.gridItem}>
                            <TextField
                                required
                                fullWidth
                                id="reSubPrice"
                                type="number"
                                label={i18n.t('GROUP_RESUB_PRICE_LABEL')}
                                value={this.state.reSubPrice}
                                onChange={this.handleReSubPriceChange}
                                error={this.state.reSubPriceError}
                                onFocus={this.handleReSubPriceFocus}
                                onBlur={this.handleReSubPriceFocus}
                            />
                        </Grid>
                        <Grid  item xs={12} sm={6} className={classes.gridItem}>
                            <CoachSelect ref={ref => this.coachSelect = ref} logout={this.props.logout}/>
                        </Grid>

                        <Grid  item xs={12} sm={6} className={classes.gridItem}>
                            <FormControl fullWidth>
                                <InputLabel>{i18n.t('TYPE_FIELD')}</InputLabel>
                                <Select
                                    fullWidth
                                    required
                                    value={this.state.type}
                                    onChange={this.handleTypeChange}
                                    name="type"
                                    id="type"
                                    error={this.state.typeError}
                                    displayEmpty
                                    renderValue={v => v ? i18n.t(v.label) : ''}
                                    onFocus={this.handleTypeFocus}
                                >
                                    {this.state.types.length
                                        ? this.state.types.map(type =>
                                            <MenuItem key={type.id.toString()}
                                                value={type.id}>{i18n.t(type.label)}</MenuItem>) : null}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid  item xs={12} sm={6} className={classes.gridItem}>
                            <FormControl fullWidth>
                                <InputLabel>{i18n.t('PACK_FIELD')}</InputLabel>
                                <Select
                                    fullWidth
                                    required
                                    value={this.state.pack}
                                    onChange={this.handlePackChange}
                                    name="pack"
                                    id="pack"
                                    error={this.state.packError}
                                    displayEmpty
                                    renderValue={v => v ? v.label : ''}
                                    onFocus={this.handlePackFocus}
                                >
                                    {this.state.packs.length
                                        ? this.state.packs.map(pack =>
                                            <MenuItem key={pack.id.toString()}
                                                value={pack.id}>{pack.label}</MenuItem>) : null}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid  item xs={12} sm={6} className={classes.gridItem}>
                            <TextField
                                required
                                fullWidth
                                id="groupEmails"
                                type="string"
                                label={i18n.t('GROUP_EMAILS_FIELD')}
                                value={this.state.groupEmails}
                                onChange={this.handleGroupEmailChange}
                                onFocus={this.handleGroupEmailFocus}
                                onBlur={this.handleGroupEmailBlur}
                                error={this.state.groupEmailsError}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} className={classes.gridItem}>
                            <Button variant="contained" color="default" onClick={this.handleCancelClick} fullWidth>
                                {i18n.t('CANCEL_BUTTON')}
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.gridItem}>
                            <Button variant="contained" color="primary" onClick={this.handleSubmitClick} fullWidth>
                                {i18n.t('SUBMIT_BUTTON')}
                            </Button>
                        </Grid>
                    </Grid>
                    <ErrorView errorList={this.state.errorList}/>
                </form>
            </div>
        );
    }
}

export default withStyles(styles)(GroupCreationForm);
