import React, {ChangeEvent, FunctionComponent, ReactElement, useEffect, useRef, useState} from 'react';
import {Theme, withStyles} from '@material-ui/core/styles';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    Button,
    Checkbox,
    CircularProgress,
    Container,
    CssBaseline,
    FormControlLabel,
    Grid,
    MenuItem,
    Modal,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@material-ui/core';
import AppBar from '../AppBar';
import {AppState, errorMessages, Token} from '../../helpers/types';
import {GroupDetailsData, PossibleGroup} from '../../helpers/responseTypes';
import {SHOW_GROUP} from './routes';
import DrawerMenu from '../DrawerMenu';
import {appBar} from '../../styles';
import moment from 'moment';
import i18n from '../../i18n/i18n';
import {NextYearGroupList, SnackBarMessage} from '../../components';
import {Connector} from '../../helpers/Connector';
import {SnackBarType} from '../../components/SnackBarMessage';
import {CheckBoxOutlineBlank} from '@material-ui/icons';
import {createStyles} from '@material-ui/styles';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
    },
    content: {
        flex: 1,
        width: '100%',
        marginTop: appBar.height,
        paddingTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    groupDetails: {
        height: '100%',
    },
    possibleNextGroupsView: {
        display: 'flex',
        flexDirection: 'column',
    },
    groupAction: {
        display: 'flex',
        flexDirection: 'column',
    },
    groupDetailsContent: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        minWidth: '100%',
    },
    modalRoot: {
        position: 'absolute',
        width: 400,
        height: 200,
        top: '50%',
        marginTop: -100,
        left: '50%',
        marginLeft: -200,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 4),
        display: 'flex',
        flexDirection: 'column',
        outline: 0,
    },
    buttonView: {
        width: '100%',
        flex: 0.3,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    displaySettings: {
        width: '100%',
        padding: theme.spacing(1),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
});

type StateProps = {
    token: Token;
    login: string;
    isAdmin: boolean;
    isSectionManager: boolean;
}

type Props = {
    classes: { [key: string]: string }
} & RouteComponentProps & StateProps;

const GroupDetails: FunctionComponent<Props> = ({
    classes,
    location,
    token,
    login,
    history,
    isSectionManager,
    isAdmin
}: Props) => {
    const realDeleteGroupId = 5;
    const [isRequestInProgress, setIsRequestInProgress] = useState(false);
    const [groupDetails, setGroupDetails] = useState<GroupDetailsData | null>(null);
    const [groupList, setGroupList] = useState<PossibleGroup[]>([]);
    const [requestFailed, setRequestFailed] = useState(false);
    const [failMessage, setFailMessage] = useState('');
    const [selectedStudents, setSelectedStudents] = useState<number[]>([]);
    const [isMoveModalOpen, setIsMoveModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedGroupChange, setSelectedGroupChange] = useState(5);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);

    // Display
    const [actionsShown, setActionsShown] = useState(false);
    const [nextYearGroupListShown, setNextYearGroupListShown] = useState(false);

    const [subState, setSubState] = useState(true);
    const [address, setAddress] = useState(false);
    const [zipCode, setZipCode] = useState(false);
    const [city, setCity] = useState(false);
    const [country, setCountry] = useState(false);
    const [subGroup, setSubGroup] = useState(false);
    const [sizes, setSizes] = useState(false);
    const [instagramHandle, setInstagramHandle] = useState(false);
    const [school, setSchool] = useState(false);
    const [gender, setGender] = useState(false);

    // Filters
    const [lastNameFilter, setLastNameFilter] = useState('');
    const [firstNameFilter, setFirstNameFilter] = useState('');

    const handleLastNameFilterChange = (e: ChangeEvent<HTMLInputElement>) => setLastNameFilter(e.currentTarget.value);

    const handleFirstNameFilterChange = (e: ChangeEvent<HTMLInputElement>) => setFirstNameFilter(e.currentTarget.value);


    useEffect(() => {
        if (!token.access) {
            history.push('/login');
        }
    }, [history, token.access]);

    const fetchStudents = useRef(() => {
        setIsRequestInProgress(true);
        Connector.getInstance().getGroupDetails(parseInt(location.pathname.replace(/\/group\/([0-9]+)/, '$1')))
            .then(group => {
                if ((group as GroupDetailsData).group) {
                    setGroupDetails(group as GroupDetailsData);
                    Connector.getInstance()
                        .getNextGroupsForGroup(parseInt(location.pathname.replace(/\/group\/([0-9]+)/, '$1')))
                        .then((possibleGroupList) => {
                            if (Array.isArray(possibleGroupList as PossibleGroup[])) {
                                setGroupList(possibleGroupList as PossibleGroup[]);
                            } else {
                                setFailMessage(possibleGroupList as string);
                                setRequestFailed(true);
                            }
                            setIsRequestInProgress(false);
                        });
                } else {
                    setFailMessage(group as string);
                    setRequestFailed(true);
                }
            })
            .catch((e: Error): void => {
                e.message === 'LOGOUT' && history.push('/login');
            });
    });

    useEffect(() => {
        if (!isRequestInProgress && groupDetails === null)
            fetchStudents.current();
    });

    const handleGroupClick = (id: number) => {
        setRequestFailed(false);
        setFailMessage('');
        if (groupDetails)
            Connector.getInstance().togglePossibleGroup(groupDetails.group.id, id)
                .then((result: boolean | string) => {
                    if (result as boolean)
                        setGroupList(groupList.map(group => {
                            if (group.id === id)
                                group.possible = !group.possible;

                            return group;
                        }));
                    else {
                        setRequestFailed(true);
                        setFailMessage(result as string);
                    }
                });
    };

    const goToNextYear = (): void => {
        if (groupDetails)
            history.push(`/group/${groupDetails.group.id}/next`, {
                id: groupDetails.group.id,
                students: groupDetails.students,
                possibleGroups: groupList.filter(group => group.possible),
            });
    };

    const isStudentChecked = (id: number): boolean =>
        selectedStudents.filter(selected => selected === id)[0] !== undefined;

    const toggleSelection = (id: number): void => {
        const newSelected = [];
        if (isStudentChecked(id)) {
            selectedStudents.map((selected): number => {
                if (selected !== id)
                    newSelected.push(selected);
                return selected;
            });
        } else {
            selectedStudents.map(selected => newSelected.push(selected));
            newSelected.push(id);
        }
        setSelectedStudents(newSelected);
    };

    const openMoveModal = () => setIsMoveModalOpen(true);

    const openDeleteModal = () => setIsDeleteModalOpen(true);

    const toggleSelectAll = () => {
        if (!groupDetails || !groupDetails.students) {
            return;
        }

        let newSelected = [];
        selectedStudents.map(selected => newSelected.push(selected));
        for (const student of groupDetails.students)  {
            const isStudentSelected = isStudentChecked(student.id);
            if (!isAllSelected) {
                if (!isStudentSelected) {
                    newSelected.push(student.id);
                }
            } else {
                newSelected = [];
            }
        }

        setSelectedStudents(newSelected);
        setIsAllSelected(!isAllSelected);
    };

    const renderGroupDetails = (): ReactElement =>
        <div className={classes.groupDetailsContent}>
            {isAdmin || isSectionManager ?
                <div className={classes.groupAction} style={{
                    visibility: actionsShown ? 'visible' : 'collapse',
                    width: actionsShown ? '15%' : '0%'
                }}>
                    <Typography variant="h5" component="h6">
                        {i18n.t('GROUP_ACTION')}
                    </Typography>
                    <Button variant="contained" color="primary" disabled={selectedStudents.length === 0}
                        onClick={openMoveModal}>
                        {i18n.t('MOVE_STUDENT_BUTTON_TITLE')}
                    </Button>
                    <Button variant="contained" color="default" disabled={selectedStudents.length === 0}
                        onClick={openDeleteModal}>
                        {i18n.t('DELETE_STUDENT_BUTTON_TITLE')}
                    </Button>
                </div> : null}
            <div className={classes.groupDetails}
                style={{
                    flex: 1.0
                         - (() => actionsShown ? 0.15 : 0.0)()
                         - (() => nextYearGroupListShown ? 0.15 : 0.0)(),
                    minWidth: `${100
                     - (() => actionsShown ? 15 : 0)()
                     - (() => nextYearGroupListShown ? 15 : 0)()}%`
                }}>
                <Typography variant="h3"
                    component="h6">
                    {groupDetails?.group.Section.name} {groupDetails?.group.name}
                </Typography>
                <Paper style={{width: '100%'}}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <TextField
                                        className={classes.input}
                                        variant="outlined"
                                        id="lastName"
                                        label={i18n.t('LAST_NAME')}
                                        name="lastName"
                                        placeholder={i18n.t('LAST_NAME')}
                                        value={lastNameFilter}
                                        onChange={handleLastNameFilterChange}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        className={classes.input}
                                        variant="outlined"
                                        id="lastName"
                                        label={i18n.t('FIRST_NAME')}
                                        name="firstName"
                                        placeholder={i18n.t('FIRST_NAME')}
                                        value={firstNameFilter}
                                        onChange={handleFirstNameFilterChange}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {(isAdmin || isSectionManager) && actionsShown
                                    ? <TableCell>
                                        <Checkbox checked={isAllSelected} value="remember"
                                            icon={<CheckBoxOutlineBlank color="primary"/>}
                                            color="primary"
                                            onChange={() => toggleSelectAll()}/>
                                        {i18n.t('SELECTION')}
                                    </TableCell>
                                    : null}
                                <TableCell>{i18n.t('LAST_NAME_FIELD')}</TableCell>
                                <TableCell>{i18n.t('FIRST_NAME_FIELD')}</TableCell>
                                <TableCell>{i18n.t('FORM_BIRTH_DATE')}</TableCell>
                                {gender
                                    ? <TableCell>{i18n.t('FORM_GENDER_TITLE')}</TableCell>
                                    : null}
                                {subState
                                    ? <TableCell>{i18n.t('SUBSCRIPTION_STATE_DEFINITION')}</TableCell>
                                    : null}
                                {nextYearGroupListShown ?
                                    <TableCell>{i18n.t('NEXT_YEAR_GROUP')}</TableCell> : null}
                                <TableCell>{i18n.t('EMAIL_FIELD')}</TableCell>
                                <TableCell>{i18n.t('PHONE_NUMBER_FIELD')}</TableCell>
                                {address
                                    ? <TableCell>{i18n.t('FORM_ADDRESS')}</TableCell>
                                    : null}
                                {zipCode
                                    ? <TableCell>{i18n.t('ZIPCODE_FIELD')}</TableCell>
                                    : null}
                                {city
                                    ? <TableCell>{i18n.t('CITY_FIELD')}</TableCell>
                                    : null}
                                {country
                                    ? <TableCell>{i18n.t('COUNTRY_FIELD')}</TableCell>
                                    : null}
                                {subGroup
                                    ? <TableCell>{i18n.t('SUB_GROUP_TITLE')}</TableCell>
                                    : null}
                                {sizes
                                    ? <TableCell>{i18n.t('SHIRT_SIZE_FIELD')}</TableCell>
                                    : null}
                                {sizes
                                    ? <TableCell>{i18n.t('SHORT_SIZE_FIELD')}</TableCell>
                                    : null}
                                {instagramHandle
                                    ? <TableCell>{i18n.t('INSTAGRAM_HANDLE_FIELD')}</TableCell>
                                    : null}
                                {school
                                    ? <TableCell>{i18n.t('SCHOOL_FIELD')}</TableCell>
                                    : null}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {groupDetails &&
                                groupDetails.students.filter(student =>
                                    student.lastName.toLowerCase().includes(lastNameFilter.toLowerCase())
                                && student.firstName.toLowerCase().includes(firstNameFilter.toLowerCase()))
                                    .map(student => {
                                        return <TableRow key={student.id}>
                                            {(isAdmin || isSectionManager) && actionsShown
                                                ?
                                                <TableCell>
                                                    <Checkbox checked={selectedStudents.includes(student.id)}
                                                        value="remember"
                                                        icon={<CheckBoxOutlineBlank color="primary"/>}
                                                        color="primary"
                                                        onChange={() => toggleSelection(student.id)}/>
                                                </TableCell> : null}
                                            <TableCell>{student.lastName}</TableCell>
                                            <TableCell>{student.firstName}</TableCell>
                                            <TableCell>
                                                {moment(student.Subscription.birthDate).format('DD/MM/YYYY')}
                                            </TableCell>
                                            {gender
                                                ? <TableCell>{student.Subscription.gender}</TableCell>
                                                : null}
                                            {subState
                                                ?
                                                <TableCell>
                                                    {i18n.t(`SUBSCRIPTION_${student.Subscription.state}`)}
                                                </TableCell>
                                                : null}
                                            {nextYearGroupListShown
                                                ?
                                                <TableCell>
                                                    {groupList.filter(group =>
                                                        group.id === student.nextYearGroupId)[0]
                                                        ? groupList.filter(group =>
                                                            group.id === student.nextYearGroupId)[0].name
                                                        : 'Aucun'}
                                                </TableCell>
                                                : null}
                                            <TableCell>
                                                {student.contactInfo ? student.contactInfo.email : null}
                                            </TableCell>
                                            <TableCell>
                                                {student.contactInfo ? student.contactInfo.phoneNumber : null}
                                            </TableCell>
                                            {address
                                                ? <TableCell>{student.Subscription.address}</TableCell>
                                                : null}
                                            {zipCode
                                                ? <TableCell>{student.Subscription.zipCode}</TableCell>
                                                : null}
                                            {city
                                                ? <TableCell>{student.Subscription.city}</TableCell>
                                                : null}
                                            {country
                                                ? <TableCell>{student.Subscription.country}</TableCell>
                                                : null}
                                            {subGroup
                                                ? <TableCell>{student.SubGroup ? student.SubGroup.name : ''}</TableCell>
                                                : null}
                                            {sizes
                                                ? <TableCell>
                                                    {student.shirtSize ? student.shirtSize.label : ''}
                                                </TableCell>
                                                : null}
                                            {sizes
                                                ? <TableCell>
                                                    {student.shortSize ? student.shortSize.label : ''}
                                                </TableCell>
                                                : null}
                                            {instagramHandle
                                                ? <TableCell>{student.Subscription.instagramHandle
                                                    ? student.Subscription.instagramHandle
                                                    : ''}</TableCell>
                                                : null}
                                            {school
                                                ? <TableCell>{student.Subscription.school
                                                    ? student.Subscription.school
                                                    : ''}/{student.Subscription.schoolLevel
                                                    ? student.Subscription.schoolLevel
                                                    : ''}</TableCell>

                                                : null}
                                        </TableRow>;
                                    }
                                    )}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
            <div className={classes.possibleNextGroupsView} style={{
                visibility: nextYearGroupListShown ? 'visible' : 'collapse',
                width: nextYearGroupListShown ? '15%' : '0%'
            }}>
                <Typography variant="h5"
                    component="h6">
                    {i18n.t('POSSIBLE_NEXT_YEAR_GROUPS')}
                </Typography>
                <NextYearGroupList groups={groupList} onGroupClick={handleGroupClick}/>
                <Button variant="contained" color="primary" fullWidth onClick={goToNextYear}>
                    {i18n.t('ASSIGN_NEXT_YEAR_GROUP')}
                </Button>
            </div>
        </div>;

    const handleMoveModalClose = () => setIsMoveModalOpen(false);

    const handleDeleteModalClose = () => setIsDeleteModalOpen(false);

    const handleNewGroupChange = (e: ChangeEvent<{ name?: string | undefined; value: unknown; }>): void =>
        setSelectedGroupChange(e.target.value as number);

    const moveStudents = () => {
        setRequestFailed(false);
        setFailMessage('');
        setIsRequestInProgress(true);
        Connector.getInstance().changeGroupForStudents(selectedGroupChange, selectedStudents)
            .then((ok: boolean | string) => {
                if (!errorMessages.includes(ok as string)) {
                    fetchStudents.current();
                    setIsMoveModalOpen(false);
                    setSelectedStudents([]);
                    setSelectedGroupChange(5);
                } else {
                    setRequestFailed(true);
                    setFailMessage(ok as string);
                }
            });
    };

    const deleteStudents = () => {
        setRequestFailed(false);
        setFailMessage('');
        setIsRequestInProgress(true);
        if (groupDetails?.group.id === realDeleteGroupId) {
            // Remove students with guardian issue
            for (const student of groupDetails.students) {
                if (!student.contactInfo && selectedStudents.includes(student.id)) {
                    selectedStudents.splice(selectedStudents.indexOf(student.id), 1);
                }
            }
            Connector.getInstance().deleteSubscriptions(selectedStudents)
                .then((ok: boolean | string) => {
                    if (!errorMessages.includes(ok as string)) {
                        fetchStudents.current();
                        setIsDeleteModalOpen(false);
                        setSelectedStudents([]);
                        setSelectedGroupChange(5);
                    } else {
                        setRequestFailed(true);
                        setFailMessage(ok as string);
                    }
                });
        } else {
            Connector.getInstance().changeGroupForStudents(realDeleteGroupId, selectedStudents)
                .then((ok: boolean | string) => {
                    if (!errorMessages.includes(ok as string)) {
                        fetchStudents.current();
                        setIsDeleteModalOpen(false);
                        setSelectedStudents([]);
                        setSelectedGroupChange(5);
                    } else {
                        setRequestFailed(true);
                        setFailMessage(ok as string);
                    }
                });
        }
    };

    const renderMoveModal = () =>
        <Modal open={isMoveModalOpen} onClose={handleMoveModalClose}>
            <div className={classes.modalRoot}>
                <Typography component="h5" variant="h6">
                    {i18n.t('GROUP_CHANGE_TITLE')}
                </Typography>
                <p>{i18n.t('SELECT_NEW_GROUP_MESSAGE')}</p>
                <Select value={selectedGroupChange} onChange={handleNewGroupChange}>
                    {groupList ? groupList.map(group => <MenuItem key={group.id} value={group.id}>
                        {group.name}
                    </MenuItem>) : null}
                </Select>
                <div className={classes.buttonView}>
                    <Button variant="contained" color="default" onClick={handleMoveModalClose}>
                        {i18n.t('CANCEL_BUTTON')}
                    </Button>
                    <Button variant="contained" color="primary" onClick={moveStudents}>
                        {i18n.t('SUBMIT_BUTTON')}
                    </Button>
                </div>
            </div>
        </Modal>;

    const renderDeleteModal = () =>
        <Modal open={isDeleteModalOpen} onClose={handleDeleteModalClose}>
            <div className={classes.modalRoot}>
                <Typography component="h5" variant="h6">
                    {i18n.t('GROUP_DELETE_TITLE')}
                </Typography>
                <p>{groupDetails?.group.id == realDeleteGroupId ? i18n.t('CONFIRM_GROUP_PERMA_DELETE_MASSAGE')
                    : i18n.t('CONFIRM_GROUP_DELETE_MESSAGE')}</p>
                <div className={classes.buttonView}>
                    <Button variant="contained" color="default" onClick={handleDeleteModalClose}>
                        {i18n.t('CANCEL_BUTTON')}
                    </Button>
                    <Button variant="contained" color="primary" onClick={deleteStudents}>
                        {i18n.t('SUBMIT_BUTTON')}
                    </Button>
                </div>
            </div>
        </Modal>;

    return (
        <div className={classes.root}>
            <CssBaseline/>
            {renderMoveModal()}
            {renderDeleteModal()}
            <AppBar login={login} currentRoute={SHOW_GROUP} onBurgerClick={() => {
                setIsMenuOpen(!isMenuOpen);
            }}/>
            <DrawerMenu isOpen={isMenuOpen}/>
            <Container component="main" className={classes.content}>
                <Paper className={classes.displaySettings}>
                    <Typography>{i18n.t('DISPLAY_SETTINGS_TITLE')}</Typography>
                    <Grid container spacing={2}>
                        {isAdmin || isSectionManager
                            ? <Grid item xs={6} sm={3}>
                                <FormControlLabel
                                    control={<Checkbox checked={actionsShown} value={actionsShown}
                                        icon={<CheckBoxOutlineBlank color="primary"/>}
                                        color="primary"
                                        onChange={(): void => setActionsShown(!actionsShown)}/>}
                                    label={i18n.t('GROUP_ACTION_DEFINITION')}
                                />
                            </Grid>
                            : null}
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                control={<Checkbox checked={nextYearGroupListShown} value={nextYearGroupListShown}
                                    icon={<CheckBoxOutlineBlank color="primary"/>}
                                    color="primary"
                                    onChange={(): void => setNextYearGroupListShown(!nextYearGroupListShown)}/>}
                                label={i18n.t('NEXT_YEAR_GROUPS_DEFINITION')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                control={<Checkbox checked={gender} value={gender}
                                    icon={<CheckBoxOutlineBlank color="primary"/>}
                                    color="primary"
                                    onChange={(): void => setGender(!gender)}/>}
                                label={i18n.t('FORM_GENDER_TITLE')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                control={<Checkbox checked={subState} value={subState}
                                    icon={<CheckBoxOutlineBlank color="primary"/>}
                                    color="primary"
                                    onChange={(): void => setSubState(!subState)}/>}
                                label={i18n.t('SUBSCRIPTION_STATE_LABEL')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                control={<Checkbox checked={address} value={address}
                                    icon={<CheckBoxOutlineBlank color="primary"/>}
                                    color="primary"
                                    onChange={(): void => setAddress(!address)}/>}
                                label={i18n.t('FORM_ADDRESS')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                control={<Checkbox checked={zipCode} value={zipCode}
                                    icon={<CheckBoxOutlineBlank color="primary"/>}
                                    color="primary"
                                    onChange={(): void => setZipCode(!zipCode)}/>}
                                label={i18n.t('ZIPCODE_FIELD')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                control={<Checkbox checked={city} value={city}
                                    icon={<CheckBoxOutlineBlank color="primary"/>}
                                    color="primary"
                                    onChange={(): void => setCity(!city)}/>}
                                label={i18n.t('CITY_FIELD')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                control={<Checkbox checked={country} value={country}
                                    icon={<CheckBoxOutlineBlank color="primary"/>}
                                    color="primary"
                                    onChange={(): void => setCountry(!country)}/>}
                                label={i18n.t('COUNTRY_FIELD')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                control={<Checkbox checked={subGroup} value={subGroup}
                                    icon={<CheckBoxOutlineBlank color="primary"/>}
                                    color="primary"
                                    onChange={(): void => setSubGroup(!subGroup)}/>}
                                label={i18n.t('SUB_GROUP_TITLE')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                control={<Checkbox checked={sizes} value={sizes}
                                    icon={<CheckBoxOutlineBlank color="primary"/>}
                                    color="primary"
                                    onChange={(): void => setSizes(!sizes)}/>}
                                label={i18n.t('SIZES_TITLE')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                control={<Checkbox checked={instagramHandle} value={instagramHandle}
                                    icon={<CheckBoxOutlineBlank color="primary"/>}
                                    color="primary"
                                    onChange={(): void => setInstagramHandle(!instagramHandle)}/>}
                                label={i18n.t('INSTAGRAM_HANDLE_FIELD')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                control={<Checkbox checked={school} value={school}
                                    icon={<CheckBoxOutlineBlank color="primary"/>}
                                    color="primary"
                                    onChange={(): void => setSchool(!school)}/>}
                                label={i18n.t('SCHOOL_FIELD')}
                            />
                        </Grid>
                    </Grid>
                </Paper>
                {isRequestInProgress ? <CircularProgress/> : groupDetails ? renderGroupDetails() : <span/>}
            </Container>
            <SnackBarMessage content={i18n.t(failMessage)} type={SnackBarType.ERROR} isOpen={requestFailed}/>
        </div>
    );
};

const mapStateToProps = (state: AppState): StateProps => {
    const {token, login, isSectionManager, isAdmin} = state.login;
    return {token, login, isSectionManager, isAdmin};
};

export default withStyles(styles)(withRouter(connect(mapStateToProps, null)(GroupDetails)));
