const isStudentMajor = (birthDate: Date | string): boolean => {
    const todayDate = new Date();
    const majorLimit = new Date();
    majorLimit.setFullYear(todayDate.getFullYear() - 18);
    return majorLimit > new Date(birthDate);
};

export {
    isStudentMajor
};
