const en = {
    LOGIN: 'Log in',
    CHANGE_LOCALE: 'Change language',
    FORM_TITLE_PART_1: 'Subscriber information',
    FIRST_NAME_FIELD: 'First Name',
    LAST_NAME_FIELD: 'Last Name',
    FORM_PERSONAL_INFO: 'Personal information',
    FORM_GENDER_TITLE: 'Gender',
    FORM_BIRTH_DATE: 'Birth date',
    NATIONALITY_FIELD: 'Nationality',
    BIRTH_PLACE_FIELD: 'Birth place',
    NEXT_STEP_BUTTON: 'Next Step',
    BACK_BUTTON: 'Back',
    RESET_FORM_BUTTTON: 'Reset',
    FORM_CONTACT: 'Contact',
    FORM_ADDRESS: 'Address',
    GENDER_RADIO_F: 'F',
    GENDER_RADIO_M: 'M',
    EMAIL_FIELD: 'Email',
    EMAIL_TOOLTIP: 'For a minor, please fill in a guardian\'s contact',
    PHONE_NUMBER_FIELD: 'Phone number',
    ADD_CONTACT_TOOLTIP: 'Add a contact',
    GUARDIAN_CONTACT_LABEL: 'Guardian contact',
    CONTACT_LABEL: 'Contact',
    GUARDIAN_LABEL: 'Guardian',
    ADDRESS_FIELD: 'Address',
    CITY_FIELD: 'City',
    ZIPCODE_FIELD: 'Postal code',
    COUNTRY_FIELD: 'Country',
    SUBMIT_BUTTON: 'Submit',
    WELCOME: 'Welcome, {{login}}',
    GO_TO_PERSONAL_SPACE: 'My account',
    LOGOUT: 'Log out',
    GO_TO_TEST_CREATION: 'Create tests',
    TEST_DATETIME_LABEL: 'Date and time of test',
    TEST_CAPACITY_LABEL: 'Slots',
    TEST_CREATION_TITLE: 'Create a new test session',
    TEST_DRAWER_ITEM: 'Test management',
    TEST_DATE_TIME: '{{date}} at {{time}}',
    TEST_REMAINING_SLOTS: '{{remaining}} slots available out of {{capacity}}',
    EDIT_TEST_ITEM: 'Edit',
    DELETE_TEST_ITEM: 'Delete',
    EDIT_TEST_TITLE: 'Edit a test',
    CANCEL_BUTTON: 'Cancel',
    SHOW_ALL_TESTS_TITLE: 'Show all test sessions',
    TEST_DELETE_CONFIRMATION_MESSAGE: 'Do you really want to delete this test session ?',
    CONFIRMATION_TITLE: 'Confirmation',
    STUDENT_DRAWER_ITEM: 'Members',
    STUDENT_CREATION_TITLE: 'Create a member',
    BIRTH_YEAR_LABEL: 'Birth year',
    BIRTH_YEAR_INFO: 'Birth year : {{birthYear}}',
    TEST_ASSIGNATION_TITLE: 'Assign to a test session',
    MIN_BIRTH_YEAR_LABEL: 'Max age',
    MAX_BIRTH_YEAR_LABEL: 'Min age',
    BIRTH_YEAR_SPAN_DESCR: '{{minBirthYear}} to {{maxBirthYear}}',
    TEST_SESSION_LIST_ITEM: '{{type}} test : {{scheduledDate}} at {{scheduledTime}}, between {{minBirthYear}} ' +
        'and {{maxBirthYear}}. {{capacity}} slots remaining.',
    STUDENT_ASSIGN_TITLE: 'Edit tests assignments',
    FNAME_ERROR: 'Invalid first name',
    LNAME_ERROR: 'Invalid last name',
    NATIONALITY_ERROR: 'Invalid nationality',
    BIRTH_DATE_ERROR: 'Invalid birth date',
    EMAIL_ERROR: 'Invalid email',
    PHONE_ERROR: 'Invalid phone number',
    SECOND_EMAIL_ERROR: 'Invalid second email',
    SECOND_PHONE_ERROR: 'Invalid second phone',
    SECTION_LABEL: 'Section',
    START_TEST_ITEM: 'Start test session',
    START_TEST_SESSION_TITLE: 'Test session, {{date}}',
    STUDENT_LIST_HEADER: 'Member',
    FAILED_TEST_HEADER: 'Failed test',
    GROUP_DRAWER_ITEM: 'Group management',
    GROUP_CREATION_TITLE: 'Create a group',
    SHOW_ALL_GROUPS_TITLE: 'Show all groups',
    GROUP_NAME_LABEL: 'Name',
    GROUP_PRICE_LABEL: 'Price',
    GROUP_RESUB_PRICE_LABEL: 'Re-sub price',
    COACH_LABEL: 'Coach',
    GROUP_NAME_ERROR: 'Invalid group name',
    GROUP_PRICE_ERROR: 'Invalid group subscription price',
    GROUP_RESUB_PRICE_ERROR: 'Invalid group subscription renewal price',
    SECTION_DRAWER_ITEM: 'Section management',
    SECTION_CREATION_TITLE: 'Create a section',
    SHOW_ALL_SECTIONS_TITLE: 'Show all sections',
    SECTION_NAME_LABEL: 'Name',
    SECTION_NAME_ERROR: 'Invalid section name',
    USER_DRAWER_ITEM: 'Users',
    USER_CREATION_TITLE: 'Create a user',
    SHOW_ALL_USERS_TITLE: 'Show all users',
    ROLE_CHOICE_TITLE: 'Assign roles',
    FIRST_NAME_ERROR: 'The first name is invalid',
    LAST_NAME_ERROR: 'The last name is invalid',
    NO_ROLE_CHOSEN: 'No roles were chosen. Please chose at least one role.',
    CREATED_COACH_EXPLANATION: 'The new user {{firstName}} {{lastName}} has successfully been created.\n' +
        'The default password is lastname.firstname, please change it promptly.',
    ADDRESS_ERROR: 'The address is invalid',
    ADDRESS_COMP_ERROR: 'The address complement is invalid',
    CITY_ERROR: 'The city is invalid',
    ZIP_CODE_ERROR: 'The postal code is invalid',
    COUNTRY_ERROR: 'The country is invalid',
    CREATED_SUBSCRIPTION_EXPLANATION: 'The inscription was taken into account.\n' +
        'To finish the subscription, please download the files below, print them,\n' +
        'sign them if necessary and bring them along with the rest of the required documents' +
        ' to the swimming pool counter',
    SETTINGS_ITEM_TITLE: 'Preferences',
    PLEASE_ENTER_PASSWORD: 'Please enter your password',
    PASSWORD_LABEL: 'Password',
    PASSWORD_ERROR: 'Please enter your password',
    WRONG_PASSWORD: 'The password is incorrect',
    PASSWORD_CONFIRMATION_LABEL: 'Confirmation',
    CONFIRMATION_ERROR: 'Please confirm your password',
    CONFIRMATION_MISMATCH: 'The password and the confirmation do not match',
    CHANGE_PASSWORD_SUCCESS: 'The password has been updated successfully',
    NO_TESTS_TITLE: 'No available test session',
    GROUP_COACH_DEFINITION: 'Coaches: {{coachList}}',
    STUDENT_COUNT_DEFINITION: 'Member count: {{studentCount}}',
    ROLE_LIST: 'Roles: {{roleList}}',
    POSSIBLE_NEXT_YEAR_GROUPS: 'Next year potential groups',
    ASSIGN_NEXT_YEAR_GROUP: 'Assign to next year groups',
    ADMIN: 'Admin.',
    COACH: 'Coach',
    SECTION_MANAGER: 'Section manager',
    CHANGE_PASSWORD_TITLE: 'Change password',
    CONFIRM_GROUP_DELETE: 'Do you really want to delete this group ?',
    CONFIRM_USER_DELETE: 'Do you really want to delete this user ?',
    GROUP_EDIT_TITLE: 'Edit group',
    ALREADY_SUBSCRIBED_NORMAL: 'The inscription/renewal process was already started. To complete the process,' +
        ' please download the files below, print them,\n' +
        'sign them if necessary and bring them along with the rest of the required documents' +
        ' to the swimming pool counter',
    SUBSCRIPTION_FORM: 'Subscription form',
    ASM_SUBSCRIPTION_FORM: 'ASM subscription form',
    SCHEDULE_DOCUMENT: 'Schedule',
    SUBSCRIPTION_OVERVIEW_TITLE: 'Subscriptions',
    SUBSCRIPTION_STATE_DEFINITION: 'Subscription state',
    SUBSCRIPTION_STATE_VALUE: '{{state}}',
    SUBSCRIPTION_scheduled: 'Scheduled',
    SUBSCRIPTION_started: 'Started',
    SUBSCRIPTION_missingDoc: 'Missing documents',
    SUBSCRIPTION_done: 'Completed',
    FIRST_NAME: 'First name',
    LAST_NAME: 'Last name',
    ACTIONS: 'actions',
    STUDENT_INFO_TITLE: 'Personal information',
    STUDENT_CONTACT_TITLE: 'Contact / Guardian',
    STUDENT_ADDRESS_TITLE: 'Address',
    IS_NEW_LABEL: 'First year',
    SUBSCRIPTION_STATE_TITLE: 'Subscription',
    SUBSCRIPTION_STATE_LABEL: 'Subscription state',
    RESEND_MAIL: 'Re-send subscription mail',
    LOGIN_TITLE: 'Login',
    USERNAME_PLACEHOLDER: 'Username',
    PASSWORD_PLACEHOLDER: 'Password',
    LOGIN_FAILED: 'Connection failed. Please check your username / password',
    BAD_REQUEST: 'Invalid data',
    UNKNOWN_ERROR: 'An unexpected error occurred. Please try again later.',
    TEST_CREATION_SUCCEEDED: 'The test session has been created successfully',
    TEST_CREATION_FAILED_BAD_REQUEST: 'A session already exists for this time.',
    TEST_CREATION_FAILED_UNKNOWN_ERROR: 'An unexpected error occurred. Please try again later.',
    TEST_EDIT_FAILED_BAD_REQUEST: 'Invalid data',
    TEST_EDIT_FAILED_UNKNOWN_ERROR: 'An unexpected error occurred. Please try again later.',
    TEST_EDIT_FAILED_NOT_FOUND: 'No test session was found.',
    TEST_ASSIGN_SUCCESS: 'The member has been added to the test session successfully',
    SUB_MAIL_SENT: 'The mail has been sent successfully',
    GROUP_ACTION: 'Action',
    NEXT_YEAR_GROUP: 'Next year group',
    SELECTION: 'Selection',
    MOVE_STUDENT_BUTTON_TITLE: 'Move',
    DELETE_STUDENT_BUTTON_TITLE: 'Delete',
    GROUP_CHANGE_TITLE: 'Group change',
    SELECT_NEW_GROUP_MESSAGE: 'Select the group in which to move the members:',
    GROUP_DELETE_TITLE: 'Delete from the group',
    CONFIRM_GROUP_DELETE_MESSAGE: 'Do you really want to delete the members from the group ?' +
        ' Deleted members will be places in the group "Aucun".',
    CONFIRM_GROUP_PERMA_DELETE_MASSAGE: 'Do you really want to delete the members from the group ?' +
        'This action cannot be undone.',
    CLICK_HERE: '(Click here)',
    GROUP_TITLE: 'Group',
    SUB_GROUP_TITLE: 'Sub-group',
    SEND_MAIL_BUTTON: 'Send mail',
    SEND_MAIL_FOR_TEST_SUCCESS: 'Mails were sent successfully',
    PROBLEM: 'Problem',
    SECONDARY_EMAIL_FIELD: 'Second email',
    SECONDARY_PHONE_NUMBER_FIELD: 'Second phone number',
    FILTERS: 'Filters',
    SUBSCRIPTION_TITLE: 'Subscription for {{firstName}} {{lastName}}',
    CREATE_NEW_GUARDIAN: 'Create a new contact / guardian',
    NEW_SESSION: 'New session: {{name}}',
    SESSION_DATE: 'Session date',
    ATTENDING: 'Present',
    EMAIL_SUBJECT_FIELD: 'Email subject',
    EMAIL_MESSAGE_FIELD: 'Message',
    MAIL_SEND_SUCCESS: 'Mails were sent successfully',
    SECTION_MANAGER_DEFINITION: 'Manager: {{sectionManager}}',
    SECTION_GROUPS_DEFINITION: 'Groups: {{groupList}}',
    CONFIRM_SECTION_DELETE: 'Do you really want to delete this section?',
    SECTION_EDIT_TITLE: 'Edit section',
    SECTION_MANAGER_TITLE: 'Manager: ',
    DISPLAY_SETTINGS_TITLE: 'Display settings',
    GROUP_ACTION_DEFINITION: 'Move / delete',
    NEXT_YEAR_GROUPS_DEFINITION: 'Next year groups',
    GROUPS_DEFINITION: 'Groups',
    ADD_GROUP_TITLE: 'Add',
    SUB_GROUP_DRAWER_ITEM: 'Sub-group management',
    SHOW_ALL_SUB_GROUPS_TITLE: 'Show all sub-groups',
    SUB_GROUP_CREATION_TITLE: 'Create a sub-group',
    DETAILS_TOOLTIP: 'Details',
    NEW_SESSION_TITLE: 'New session',
    DELETE_TOOLTIP: 'Delete',
    EDIT_TOOLTIP: 'Edit',
    MAIL_TOOLTIP: 'Mails',
    SUB_GROUPS_TOOLTIP: 'Sub-groups',
    CURRENT_SUB_GROUP: 'Current sub-group',
    NONE_M: 'None',
    NO_SUB_GROUP: 'No sub-group',
    CREATE_TOOLTIP: 'Create',
    SUB_GROUP_NAME_FIELD: 'Sub-group name',
    CONFIRM_DELETE_SUB_GROUP_MESSAGE: 'Do you really want to delete the sub-group?',
    SUB_GROUP_CREATE_SUCCESS: 'The sub-group has been successfully created.',
    SUB_GROUP_DELETE_SUCCESS: 'The sub-group has been successfully deleted.',
    SESSION_DRAWER_ITEM: 'Sessions',
    SESSION_CREATION_TITLE: 'Create a new session',
    SHOW_ALL_SESSIONS_TITLE: 'Show all sessions',
    CHOOSE_SUB_GROUP_SESSION_TITLE: 'Choose a sub-group',
    WHOLE_GROUP_SELECT_ITEM: 'Whole group',
    NO_STUDENT_TEXT: 'No member in this (sub-)group',
    IS_LATE: 'Late',
    INVALID_DATE_MESSAGE: 'Invalid date',
    SECTION_REMOVE_GROUP_SUCCESS: 'The group has been successfully moved to "Aucune".',
    SECTION_REMOVE_GROUP_FAILURE: 'An error occurred while moving the group to "Aucune".',
    CONFIRM_STUDENT_DELETE: 'Do you really want to delete the member?',
    BIRTH_PLACE_ERROR: 'Invalid birth place',
    FFN_LICENSE: 'FFN License',
    BIRTH_PLACE: 'Birth place',
    GENDER_ERROR: 'Invalid gender',
    DOCUMENTS_STATE: 'Documents',
    SUBSCRIPTION_FORM_LABEL: 'Subscription form',
    RULES_LABEL: ' ASM Natation Rules',
    ID_PHOTO_LABEL: 'ID photo',
    ASM_PDF_LABEL: 'ASM General form',
    MEDICAL_CERTIFICATE_LABEL: 'Medical certificate',
    PAYMENT_LABEL: 'Payment',
    FFN_LICENSE_LABEL: 'FFN License',
    PROCESS_DATE_LABEL: 'Validation date',
    TEST_TYPE_LABEL: 'Test type',
    NATATION_LOISIR: 'Swimming',
    ADULTE: 'Adult',
    'WATER-POLO': 'Water-Polo',
    PLONGEON_COMPETITION: 'Diving',
    PLONGEON_LOISIR: 'Leisure Diving',
    SYNCHRO_COMPETITION: 'Synchronized Swimming',
    SYNCHRO_LOISIR: 'Leisure Synchronized Swimming',
    AUTRES: 'Others',
    NATATION_COMPETITION: 'Competitive Swimming',
    OFFICIALS: 'Officials',
    DATE_TITLE: 'Date',
    PARTICIPANTS_TITLE: 'Participants',
    ABSENCE_TITLE: 'Absences',
    LATE_TITLE: 'Late',
    EDIT_SESSION: 'Edit session',
    RANGE_START: 'Start',
    RANGE_END: 'End',
    CREATE_SESSION_SUCCESS: 'The session has been successfully created',
    TEST_TYPE: '{{type}} test',
    EXPORT_BUTTON: 'Export (.xlsx)',
    TEST_LOCATION_LABEL: 'Location',
    SHIRT_SIZE_FIELD: 'T-shirt size',
    SHORT_SIZE_FIELD: 'Short size',
    SHIRT_SIZE_ERROR: 'Invalid T-shirt size',
    SHORT_SIZE_ERROR: 'Invalid short size',
    DOCUMENT_LABEL: 'Document {{index}}:',
    PACK_FIELD: 'Gear pack',
    SIZES_TITLE: 'Sizes',
    CODE_OF_CONDUCT_LABEL: 'Swimmer\'s Code of Conduct',
    PARENT_CODE_OF_CONDUCT_LABEL: 'Parents\' Code of Conduct',
    TYPE_ERROR: 'Invalid type',
    PACK_ERROR: 'Invalid pack',
    UPDATE_SECTION_SUCCESS: 'The section was updated successfully',
    MISSING_DATA: 'Invalid data',
    INSTAGRAM_HANDLE_FIELD: 'Instagram handle',
    ACCEPT_PRIVACY_TERMS: 'I have read and acknowledge the following privacy conditions:',
    PRIVACY_TERMS: '\nLes informations nominatives sont collectées par l\'ASM Natation, par le biais de ce' +
        ' formulaire,' +
        ' dans le cadre du traitement ayant pour finalité \u00ab Gestion des membres des associations et des ' +
        'fédérations d\'associations \u00bb. Conformément aux dispositions de la Loi n° 1.165, modifiée, du ' +
        '23 décembre 1993, relative à la protection des informations nominatives, vous disposez de droits d"accès, ' +
        'de rectification et de suppression de vos informations nominatives en vous rendant au siège de l\'ASM ' +
        'Natation (7 avenue des Castellans) ou sur demande écrite envoyée à ',
    GENERIC_ERROR_MESSAGE: 'An unexpected error occurred. Please try again later.',
    CREATE_NEW_MEMBER: 'Create new member',
    SCHOOL_ERROR: 'Invalid school',
    SCHOOL_FIELD: 'School',
    SCHOOL_LEVEL_ERROR: 'Invalid school level',
    SCHOOL_LEVEL_FIELD: 'Level',
    SHOW_ALL_TIMESLOTS_TITLE: 'Show all time slots',
    CREATE_NEW_TIMESLOT_TITLE: 'Create new time slot',
    SEND_INVOICE: 'Send invoice',
    INVOICE: 'Subscription certificate',
    GROUP_EMAILS_FIELD: 'Group Emails',
    EMAIL_LIST_ERROR: 'Invalid format for email list. Expected format: mail1@mail.com,mail2@mail.com',
};

export default en;
